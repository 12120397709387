@charset "utf-8";

* {
    margin: 0;
    padding: 0;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
    font-family: "微软雅黑";
}

fieldset,
img {
    border: 0;
}

ul,
ol,
li {
    list-style: none;
    font-family: 'Microsoft YaHei';
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var,
optgroup {
    font-style: normal;
    font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal;
}

abbr,
acronym {
    border: 0;
    font-variant: normal;
}

input,
button,
textarea,
select,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    background-color: #fff;
    border: none;
}

code,
kbd,
samp,
tt {
    font-size: 100%;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

/*@purpose To enable resizing for IE */
/*@branch For IE6-Win, IE7-Win */
input,
button,
textarea {
    *font-size: 100%;
}

ol,
ul {
    list-style: none;
}

/* tables still need 'cellspacing="0"' in the markup */
table,
tr,
td {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0;
    padding: 0;
}

caption,
th {
    text-align: left;
}

sup,
sub {
    font-size: 100%;
    vertical-align: baseline;
}

/* remember to highlight anchors and inserts somehow! */
blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

/**Common Css**/
.fr {
    float: right;
    *display: inline;
}

.fl {
    float: left;
    *display: inline;
}

/*文本超出显示省略号*/
.ehn,
.otw,
.tow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*清除浮动*/
.clearfix {
    clear: both;
    *zoom: 1;
}

.clearfix:after {
    display: block;
    clear: both;
    content: "\0020";
    visibility: hidden;
    height: 0;
}

html,
body {
    font-family: 'Helvetica Neue';
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

p{
    font-family: 'Helvetica Neue';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Optima';
    font-weight: 700;
}


.success {
    color: green;
}

.error {
    color: red;
}

.progress.Live {
    width: 100%;
    background-color: rgba(45, 171, 80, 0.1);
}

.progress.Pending {
    width: 100%;
    background-color: rgba(211, 211, 218, 0.3);
}

.progress-value.Pending {
    background-color: rgba(211, 211, 218, 1);
}

.progress.Successfully {
    background-color: #728AE0;
}

.progress.Unsuccessfully {
    background-color: rgba(0,0,0,0.1);
}

.progress-value.Unsuccessfully {
    background-color: rgba(0,0,0,0.4);
}

.progress-value.Live {
    background-color: rgba(45, 171, 80, 1);
}


.progress {
    width: 100%;
    background-color: rgba(211, 211, 218, 0.3);
}

.progress-value.Closed {
    background-color: rgba(211, 211, 218, 1)
}


.progress.Filled {
    width: 100%;
    background-color: rgba(7, 123, 230, 0.1);
}

.progress-value.Filled {
    background-color: rgba(7, 123, 230, 1)
}
